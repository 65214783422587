<section class="card min-h-full">
  <!-- header -->
  <h2 class="flex items-center gap-3">
    <span class="material-symbols-rounded"> phonelink_ring </span>
    Canais ({{ channelsService.channels.length }}/{{
      userService.getChannelLimit()
    }})
    <button
      [routerLink]="[{ outlets: { modal: ['add', ''] } }]"
      class="ml-auto tertiary !px-1 md:!px-3"
      [disabled]="
        channelsService.channels.length >= userService.getChannelLimit()
      "
    >
      <span class="material-symbols-rounded m-auto text-black/60"> add </span>
      @if (sharedService.screenSize !== 's') {
        Adicionar
      }
    </button>
  </h2>
  <!-- error -->
  @if (channelsService.channels.length >= userService.getChannelLimit()) {
    <p
      class="flex-col md:flex-row p-3 border border-solid rounded-md bg-red-400/10 flex md:items-center gap-3 border-red-400"
    >
      <b class="flex-1"
        >O limite de canais para seu plano ({{ userService.getChannelLimit() }})
        foi atingido.</b
      >
      <a
        class="no-underline w-full md:w-fit"
        target="_blank"
        href="https://wa.me/559181052869?text=Olá%2C+gostaria+de+contratar+mais+canais"
      >
        <button class="w-full">Adquirir mais canais</button>
      </a>
    </p>
  }

  <!-- body -->
  @if (channelsService.channels.length > 0) {
    <p class="text-balance text-black/60">
      Crie canais de atendimento e os ative como uma sessão do WhatsApp Web.
      Ative chatbots a um canal para habilitar respostas automáticas.
      <a
        href="https://home.blu.direct/blog/visao-geral-chatbot#criando-e-vinculando-um-canal"
        target="_blank"
        class="font-bold text-accent-600"
        >Saiba mais</a
      >
    </p>
    <kor-table columns="2fr 2fr 1fr 1fr 1fr" readonly>
      <kor-table-row slot="header">
        <kor-table-cell head>Nome</kor-table-cell>
        <kor-table-cell head>Número ativo</kor-table-cell>
        <kor-table-cell head>Chatbot ativo</kor-table-cell>
        <kor-table-cell head>Canal ativo</kor-table-cell>
        <kor-table-cell head></kor-table-cell>
      </kor-table-row>
      @for (channel of channelsService.channels; track channel) {
        <kor-table-row>
          <kor-table-cell class="flex items-center">
            <b>{{ channel.name }}</b>
          </kor-table-cell>
          <kor-table-cell>{{ channel.numbers[0] || '–' }} </kor-table-cell>
          <kor-table-cell
            (click)="$event.stopPropagation()"
            [routerLink]="[
              { outlets: { modal: ['select-flow', channel.uid] } }
            ]"
          >
            @if (channel.activeFlow) {
              <button class="tertiary px-3">Alterar</button>
            } @else {
              <button class="tertiary" label="Escolher">Escolher</button>
            }
          </kor-table-cell>
          <kor-table-cell>
            @if (channel.status === 'CONNECTED') {
              <span class="material-symbols-rounded block text-green-600">
                check_circle
              </span>
            } @else {
              <button
                class="tertiary px-3"
                (click)="$event.stopPropagation()"
                [routerLink]="[
                  { outlets: { modal: ['activate', channel.uid] } }
                ]"
              >
                Ativar
              </button>
            }
          </kor-table-cell>
          <kor-table-cell class="justify-end">
            <button
              class="secondary"
              [routerLink]="[{ outlets: { modal: ['add', channel.uid] } }]"
            >
              <span class="material-symbols-rounded"> edit </span>
            </button>
          </kor-table-cell>
        </kor-table-row>
      }
    </kor-table>
  } @else {
    <!-- empty state -->
    <div
      class="max-w-80 min-h-80 text-center flex flex-col items-center gap-4 justify-center m-auto"
    >
      <h1>Crie seu primeiro canal</h1>
      <p class="leading-snug text-black/60">
        Crie canais de atendimento e os ative como uma sessão do WhatsApp Web.
        Ative chatbots a um canal para habilitar respostas automáticas.
      </p>

      <a
        href="https://home.blu.direct/blog/visao-geral-chatbot#criando-e-vinculando-um-canal"
        target="_blank"
        class="no-underline"
      >
        <button>Saiba mais</button>
      </a>
    </div>
  }
</section>

<!-- modals -->
<router-outlet name="modal"></router-outlet>
