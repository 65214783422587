<!-- if user has created campaigns -->
@if (userService.onboardingStepVisible('createdCampaign')) {
  <!-- chart -->
  <div class="card analytics">
    <h2><kor-icon icon="insights"></kor-icon>Últimos 30 dias</h2>
    @if (!loading && chartData) {
      <div class="tabs">
        <blu-kpi
          [active]="selectedChart === 'messages'"
          (click)="
            selectChart('messages');
            analyticsService.logEvent('admin_messages_kpi_click')
          "
          [button]="true"
          [value]="chartService.messages.currentPeriod.sum"
          [label]="'Mensagens'"
          [trend]="
            chartService.messages.currentPeriod.sum -
            chartService.messages.previousPeriod.sum
          "
        ></blu-kpi>
        <blu-kpi
          [active]="selectedChart === 'campaigns'"
          (click)="
            selectChart('campaigns');
            analyticsService.logEvent('admin_campaigns_kpi_click')
          "
          [button]="true"
          [value]="chartService.campaigns.currentPeriod.sum"
          [label]="'Disparos'"
          [trend]="
            chartService.campaigns.currentPeriod.sum -
            chartService.campaigns.previousPeriod.sum
          "
        ></blu-kpi>
      </div>
      <div class="chart-wrapper">
        <ngx-charts-bar-vertical
          [xAxis]="true"
          [yAxis]="true"
          [results]="chartData"
          [animations]="false"
          [scheme]="chartService.settings.colorScheme3"
          [xAxisTickFormatting]="formatDateTick"
        >
        </ngx-charts-bar-vertical>
      </div>
    } @else {
      <!-- else, show empty state -->
      <div class="chart-empty-state">
        <img src="assets/images/chart_empty-state.png" height="200" />
        <p>Nenhum disparo realizado até o momento</p>
      </div>
    }
  </div>
  <!-- ranking -->
  <blu-ranking></blu-ranking>
  <!-- todays campaigns -->
  <blu-campaigns-list></blu-campaigns-list>
} @else {
  <div class="card h-full items-center justify-center">
    <div class="max-w-80 text-center flex flex-col items-center gap-4">
      <h1>👋 Novo no blü?</h1>
      <p class="leading-snug text-black/60">
        Antes de começar a explorar, que tal conferir nosso tutorial para tirar
        o máximo proveito do painel de administradores?
      </p>

      <a
        href="https://home.blu.direct/blog/visao-geral-painel-administrador"
        target="_blank"
        class="no-underline"
        (click)="analyticsService.logEvent('admin_help_dashboard_click')"
      >
        <button>Saiba mais</button>
      </a>
    </div>
  </div>
}

<!-- modal -->
<router-outlet name="modal"></router-outlet>
