import { Injectable, inject } from '@angular/core';
import { orderBy, serverTimestamp, where } from '@angular/fire/firestore';
import {
  ChannelStatus,
  FirestoreCollections,
  IChannel,
  IHydratedChannel,
  OnboardingStepKey,
} from 'libs/shared/src/lib/interfaces';
import { SharedBluApiService } from 'libs/shared/src/lib/services/blu-api.service';
import { SharedFirestoreService } from 'libs/shared/src/lib/services/firestore.service';
import { OnboardingService } from './onboarding.service';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  private firestore = inject(SharedFirestoreService);
  private bluApiService = inject(SharedBluApiService);
  private onboardingService = inject(OnboardingService);
  private alertService = inject(AlertService);

  channels: IHydratedChannel[] = [];

  async getChannels(orgId: string) {
    if (this.channels.length) return;

    const channelsSnap = await this.firestore.getDocs(
      FirestoreCollections.Channel,
      where('deletedAt', '==', null),
      where('organization', '==', orgId),
      orderBy('createdAt', 'desc'),
    );
    const channels = channelsSnap.docs.map(
      (doc) => doc.data() as IHydratedChannel,
    );
    this.channels = channels;

    for (const channel of channels) {
      if (!channel.uid) return;
      try {
        const res = (await this.bluApiService.getChannelStatus(
          channel.uid,
        )) as any;
        channel.status = res.status;
      } catch (error) {
        console.error(error);
      }
    }
  }

  async getChannel(channelId: string): Promise<IChannel | undefined> {
    if (this.channels.length) {
      return this.channels.find((channel) => channel.uid === channelId);
    }
    return this.firestore.getDoc<IHydratedChannel>(
      FirestoreCollections.Channel,
      channelId,
    );
  }

  async getChannelByNumber(number: string) {
    const ch = this.channels.find((channel) =>
      channel.numbers.includes(number),
    );
    if (ch) return ch;
    const chs = await this.firestore.getDocs<IHydratedChannel>(
      FirestoreCollections.Channel,
      where('numbers', 'array-contains', number),
    );
    if (!chs.size) return;
    return chs.docs[0].data();
  }

  async updateChannel(
    channelId: string,
    hydratedChannel: Partial<IHydratedChannel>,
  ): Promise<void> {
    const channel = { ...hydratedChannel };
    delete channel.status;
    await this.firestore.updateDoc(
      FirestoreCollections.Channel,
      channelId,
      channel,
    );
    const index = this.channels.findIndex((c) => c.uid === channelId);
    if (index > -1) {
      this.channels[index] = Object.assign(this.channels[index], channel);
    }
  }

  async createChannel(channel: IChannel): Promise<void> {
    const existingChannel = await this.getChannelByNumber(channel.numbers[0]);
    if (existingChannel && existingChannel.uid) {
      if (existingChannel.organization !== channel.organization) {
        this.alertService.alert({
          title: 'Número já utilizado',
          message:
            'Esse número já está cadastrado em outra organização. Fale conosco para transferí-lo.',
        });
        return;
      }
      await this.firestore.updateDoc(
        FirestoreCollections.Channel,
        existingChannel.uid,
        {
          name: channel.name,
          deletedAt: null,
          operators: channel.operators,
        },
      );
      this.channels.push({ ...existingChannel } as IHydratedChannel);
      return;
    }
    const chRef = await this.firestore.addDoc(
      FirestoreCollections.Channel,
      channel,
    );
    this.onboardingService.completeStep(OnboardingStepKey.createdChannel);
    this.channels.push({ ...channel, uid: chRef.id } as IHydratedChannel);
  }

  async deleteChannel(channelId: string): Promise<void> {
    await this.firestore.updateDoc(FirestoreCollections.Channel, channelId, {
      deletedAt: serverTimestamp(),
    });
    const index = this.channels.findIndex((c) => c.uid === channelId);
    if (index > -1) this.channels.splice(index, 1);
  }

  setChannelToConnected(channelId: string): void {
    for (const channel of this.channels) {
      if (channel.uid === channelId) {
        channel.status = ChannelStatus.CONNECTED;
        return;
      }
    }
  }
}
